import React from 'react';
import * as pcStyles from './SelectField.module.css';
import * as mpsStyles from './SelectField.mps.module.css';
import { storeStyle } from '../../../common/util';

const styles = storeStyle({ mpsStyles, pcStyles });
const SelectField = ({
  id,
  label,
  placeholder,
  value,
  handleChange,
  required,
  customHandler,
  disabled,
  data = []
}) => {
  return (
    <div className={styles.root}>
      {label && <label htmlFor={id}>{label}</label>}
      <select
        disabled={disabled}
        id={id}
        value={value}
        onChange={customHandler ? customHandler : e => handleChange(id, e.target.value)}
      >
        {placeholder && <option value={''}>{placeholder}</option>}
        {data.map(option => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectField;
