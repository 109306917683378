import React from 'react';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';

import { Box, Flex, Text } from '@chakra-ui/react';
import * as styles from './Preview.module.scss';

const Preview = ({ values }) => {
    
  return (
    <div className={`${styles.root} ${styles[values.theme]}`}>
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        className="gc-header"
        py={2}
        px={4}>
        <Text fontWeight={'bold'}>Gift Cerfiticate</Text>
        <Text fontWeight={'bold'}>
          Code:&nbsp;
          <Text as="span" fontWeight={'light'}>
            XXX-XXX-XXX-XXX
          </Text>
        </Text>
      </Flex>
      <Box py={4} className="gc-body">
        <Flex mb={8} justifyContent={'space-between'} alignItems={'center'}>
          <h3 fontWeight={'bold'}>Pet Chemist Online</h3>
          <h3 fontWeight={'bold'}>
            
            {values.amount ? (
              <CurrencyFormatter
                currency="AUD"
                amount={parseFloat(values.amount, 10)}
                appendZero={true}
              />
            ) : (
              <>$0.00</>
            )}
          </h3>
        </Flex>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Text>
            To:&nbsp;
            <Text as="span" fontWeight={'bold'}>
              {values.recipients_name}
            </Text>
          </Text>
          <Text>
            From:&nbsp;
            <Text as="span" fontWeight={'bold'}>
              {values.your_name}
            </Text>
          </Text>
        </Flex>
      </Box>
      <Box textAlign={'right'} className="gc-footer"
      
      py={2}
      px={4}>
        <Text fontWeight={'bold'}>
          Redeem your gift certificate at
          <Text as="span" fontWeight={'light'}>&nbsp;
            https://petchemist.com.au/
          </Text>
        </Text>
      </Box>
    </div>
  );
};

export default Preview;
