import React from 'react';
import PurchaseForm from '../../components/molecules/PurchaseForm/PurchaseForm';
import GiftCertificatesTab from '../../components/organisms/GiftCertificatesTab/GiftCertificatesTab';
import Layout from '../../components/organisms/Layout/Layout';
import Seo from '../../components/organisms/Seo/Seo';

import * as styles from './giftCertificates.module.css';

const GiftcertificatesPage = () => {
  return (
    <Layout>
      <Seo title="Purchase Gift Certificates" />

      <div className={styles.root}>
        <GiftCertificatesTab pageId={'purchase-gift-certificate'}>
          <div>
              <p className='subtext'>
                Purchase your gift cards using the fields below, as they are
                only available in a unique transaction without additional
                product.
              </p>
            <PurchaseForm />
          </div>
        </GiftCertificatesTab>
      </div>
    </Layout>
  );
};

export default GiftcertificatesPage;
