import React, { useContext, useState } from 'react';
import CartContext from '../../../context/CartProvider';
import { bcApi } from '../../../helpers/bigcommerce';

import { validateEmail } from '../../../helpers/general';
import Button from '../../atoms/Button/Button';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import FormInputField from '../../atoms/FormInputField/FormInputField';
import SelectField from '../../atoms/SelectField/SelectField';
import Preview from './Preview';

import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useToast
} from '@chakra-ui/react';
import * as styles from './PurchaseForm.module.css';

const min = 1;
const max = 500;

const theme = [
  '',
  'Birthday',
  'Girl',
  'Boy',
  'Celebration',
  'Christmas',
  'General'
];

const validForm = data => {
  if (!data.your_name) {
    return 'Your Name is required.';
  }
  if (!data.recipients_name) {
    return 'Recipients Name is required.';
  }
  if (!data.your_email) {
    return 'Your email address is required.';
  }
  if (!validateEmail(data.your_email)) {
    return 'Invalid your email address.';
  }
  if (!data.recipients_email) {
    return 'Your recipients email is required.';
  }
  if (!validateEmail(data.recipients_email)) {
    return 'Invalid recipients email address.';
  }
  if (!data.amount) {
    return 'Amount is required.';
  }
  if (!data.theme) {
    return 'Theme is required.';
  }
  if (data.amount < min || data.amount > max) {
    return `You must enter a certificate amount between $${min}.00 and $${max}.00`;
  }
};

const PurchaseForm = () => {
  const toast = useToast();
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    your_name: '',
    recipients_name: '',
    your_email: '',
    recipients_email: '',
    amount: '',
    theme: '',
    optional_message: '',
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const onHandleChange = (id, value) => {
    let tempValues = Object.assign({}, values);
    tempValues[id] = value;
    setValues(tempValues);
  };

  const cart = useContext(CartContext);

  const onSubmit = () => {
    let error = validForm(values);
    let title = 'Required fields are missing';

    if(!agree) {
      error = 'Please agree to the terms and conditions.';
      title = 'Terms and Conditions';
    }


    if (error) {
      toast({
        title: title,
        description: error,
        status: 'error',
        position: 'top-right',
      });
      return;
    }
    const reqBody = {
      gift_certificates: [
        {
          name: `$${values.amount} Gift Certificate`,
          theme: values.theme || 'General',
          amount: values.amount,
          quantity: 1,
          sender: {
            name: values.your_name,
            email: values.your_email
          },
          recipient: {
            name: values.recipients_name,
            email: values.recipients_email
          },
          message: values.optional_message
        }
      ]
    };
    
    setLoading(true);
    bcApi(
      `carts${
        typeof cart?.state?.cart?.cartId === 'undefined'
          ? ''
          : `/${cart?.state?.cart?.cartId}/items`
      }`,
      'POST',
      reqBody
    ).then(({ response, status }) => {
      if (status === 404) {
        toast({
          title: 'An error occurred',
          description: "Sorry, something went wrong. Try again later.",
          status: 'error',
          position: 'top-right',
        });
        setDialogOpen(true);
        setValues({
          your_name: '',
          recipients_name: '',
          your_email: '',
          recipients_email: '',
          amount: '',
          optional_message: ''
        });
      } else {
        setValues({
          your_name: '',
          recipients_name: '',
          your_email: '',
          recipients_email: '',
          amount: '',
          optional_message: ''
        });
        window.location.href = `/confirm-order?cache=${Date.now()}`;
      }
    })
    .finally(() => {
      setLoading(false);
    });
      ;
  };

  return (
    <div className={styles.purchaseform}>
      <div
        className={`grid grid-50 ${styles.inputGridGap} ${styles.inputGridWrapp}`}
      >
        <FormInputField
          id="your_name"
          type="input"
          labelName="Your Name"
          value={values['your_name']}
          handleChange={onHandleChange}
        />

        <FormInputField
          id="your_email"
          type="email"
          labelName="Your Email"
          value={values['your_email']}
          handleChange={onHandleChange}
        />
      </div>
      <div
        className={`grid grid-50 ${styles.inputGridGap} ${styles.inputGridWrapp}`}
      >
        <FormInputField
          id="recipients_name"
          type="input"
          labelName="Recipients Name"
          value={values['recipients_name']}
          handleChange={onHandleChange}
        />
        <FormInputField
          id="recipients_email"
          type="email"
          labelName="Recipients Email"
          value={values['recipients_email']}
          handleChange={onHandleChange}
        />
      </div>
      <div
        className={`grid grid-50 ${styles.inputGridGap} ${styles.inputGridWrapp}`}
      >
        <FormInputField
          id="amount"
          type="number"
          labelName="Amount"
          value={values['amount']}
          min={min}
          max={max}
          handleChange={onHandleChange}
        />
        <SelectField
          label={'Theme'}
          id={'theme'}
          handleChange={onHandleChange}
          value={values['theme']}
          data={theme.map(value => ({
            text: value,
            value: value
          }))}
        />
      </div>

      <FormInputField
        id="optional_message"
        type="textarea"
        labelName="Optional Message"
        value={values['optional_message']}
        handleChange={onHandleChange}
        max={200}
        showCount
      />

      <Checkbox
        isChecked={agree}
        label={'I agree that gift cards are non-refundable'}
        action={() => setAgree(!agree)}
        id={'agree'}
        name={'agree'}
      />

      <Flex mt={16} gap={4} justifyContent={'center'}>
        <Button type="span" level="ghost" onClick={() => setDialogOpen(true)}>
          Preview
        </Button>
        <Button loading={loading} disabled={loading} type="span" level="tertiary" onClick={() => onSubmit()}>
          Add to Cart
        </Button>
      </Flex>

      <Modal
      
        isOpen={dialogOpen}
        size={'2xl'}
        isCentered
        onClose={() => setDialogOpen(false)}
      >
        <ModalOverlay />
        <ModalContent p={8}  m={8}>
          <ModalCloseButton />
          <ModalBody overflowY={'auto'} p={0}>
            <Preview values={values} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default PurchaseForm;
